<template>
  <div class="relative w-[300px] rounded-lg" >
    <div class="w-full h-[480px] relative shadow-md rounded-lg">
      <div class="absolute top-0 right-0 bottom-0 left-0">
        <img 
          class="w-full h-full object-contain object-right-top" 
          src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/72270ae6-ee3f-4ad9-c93d-67b62cf08e00/hd"
        />  
      </div>
      <div class="absolute top-0 right-0 bottom-0 left-0">
        <img 
          class="w-full h-full object-contain object-right-bottom" 
          src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/82741978-4564-41e8-cf59-354d1047ad00/hd"
        />  
      </div>
    </div>

    <div class="text-center" style="color: #AE6344;">
      
      <div  
        class="text-[12px] w-44 px-5  text-center absolute left-0" 
        style="top: 95px;"
      >
        <div 
          class="capitalize text-center py-2 tracking-wide text-[36px]" 
          :style="{
            fontFamily: props?.theme?.fonts?.welcome,
            color: '#AE6344',
            lineHeight: 1,
          }"
        >
          <p v-if="props?.brideName">{{props?.brideName}}</p>
          <p v-if="props?.groomName">& {{props?.groomName}}</p>
        </div>
      </div>
    </div>

    <div  
      class=" w-44 flex justify-center absolute left-0" 
      style="top: 180px;"
    >
      <img class="mt-2 w-20 h-auto object-contain" src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/89fde049-920f-4038-8652-d5a31613d100/original" alt="">
    </div>


    <div
      class="text-center w-44"
      style="position: absolute; top: 205px; left: 0;"
    >
      <div
        class="text-[11px] capitalize text-center tracking-wide"
        style="font-family: cinzel; color: #AE6344;"
      >
        <p >{{dateNumber}}.{{mount}}.{{year}}</p>
      </div>
    </div>

    <div
      class="text-center w-44"
      style="position: absolute; top: 225px; left: 0;"
    >
      <div
        class="text-[8px] capitalize text-center tracking-wide"
        style="font-family: cinzel; color: #AE6344;"
      >
        <p class="text-center tracking-wide uppercase ">OUR SPECIAL DAY</p>
      </div>
    </div>

    <FooterButtons :theme="theme" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import FooterButtons from './FooterButtons.vue'
const props = defineProps({
  brideName: { type: String, default: 'Bride'},
  groomName: { type: String, default: 'Groom'},
  eventDate: {type: String, default: '2014-12-25'},
  eventTimeStart: { type: String, default: '00:00'},
  theme: { type: Object, default: {}}
})

//-----
const dateNumber = computed( ()=> dayjs(props?.eventDate).format('DD') ||  dayjs().format('DD'))
const dayName = computed( ()=> dayjs(props?.eventDate).format('dddd') ||  dayjs().format('dddd'))
const mount = computed( ()=> dayjs(props?.eventDate).format('MMM') ||  dayjs().format('MMM'))
const year = computed( ()=> dayjs(props?.eventDate).format('YYYY') ||  dayjs().format('YYYY'))
const time = computed( ()=> props?.eventTimeStart ||  '00:00')
//-----
</script>

<style scoped>

</style>