import { useFirestorePhotoWish } from "~/composables/useFirebasePhotowish";
import { useEvent } from "~/composables/useEvent";
import { useAuthStore } from "@/stores/auth";
import { useTemplateStore } from "@/stores/template";
import { db } from "@/services/firebase/Instance";
import _ from "lodash"
import { doc, getDoc, setDoc } from "firebase/firestore";

export const useEventStore = defineStore("event", {
  state: () => ({
    eventId: null,
    event: null,
    unsubScribe: null,
  }),
  getters: {
    getWeddingTitle(): any {
      try {

        let str = []
        if (this.event?.information?.bride?.firstName) {
          str.push(this.event?.information?.bride?.firstName)

        }
        if (this.event?.information?.groom?.firstName) {
          str.push("&")

          str.push(this.event?.information?.groom?.firstName + "'s Wedding")
        }

        return str.join(' ')
      } catch (error) {
        return null
      }
    },
    getPackage(): string {
      return this.event?.package || 'free'
    },
    isRealOwner(): boolean { // เจ้าของ Event 
      const authStore = useAuthStore();
      return this.event?.user[authStore.uid] ? true : false;
    },
    isOwner(): boolean { // check for invite mode
      const authStore = useAuthStore();

      if (authStore?.isDebugMode) {
        return true
      }

      if (authStore?.role === 'admin') {
        return true
      }

      if (authStore?.user?.inviteMode === 'editor') {
        return true
      }

      const isOwner = this.event?.user[authStore.uid] || false
      return isOwner
    },
  },
  actions: {
    async main() {
      const authStore = useAuthStore();
      const eventId = authStore.user && authStore.user.eventId;

      this.eventId = eventId;
    },
    async checkEventAlready(eventId: string) {
      const isAlreadyExits = await useEvent().checkEventAlreadyExists(eventId);
      if (!isAlreadyExits) {
        return false
      }

      return true
    },
    convertEventIdFromLineId(lineProfile: any) {
      const eventId = this.convertUserIdToEventId(lineProfile.userId)
      this.eventId = eventId
    },
    async getEventById(_eventId: string,): Promise<void> {
      return await useEvent().getEvent(_eventId);
    },
    async createEvent(_eventId: string, payload: any): Promise<void> {
      await useEvent().insertEvent(_eventId, payload);

      if (payload.userInfo) {
        await this.updateMetaConvention(_eventId, payload);
      }
    },
    async updateEvent(payload: any): Promise<void> {
      await useEvent().updateEvent(this.eventId, payload);
    },
    async setMergeEvent(payload: any): Promise<void> {
      await useEvent().setUpdate(this.eventId, payload);

      if (payload.userInfo) {
        await this.updateMetaConvention(this.eventId, payload);
      }
    },
    async setMergeEventByEventId(_eventId: string, payload: any): Promise<void> {
      await useEvent().setUpdate(this.eventId, payload);
    },
    async updateEventNotify(eventId: string, payload: any) {
      await useEvent().updateEvent(eventId, payload);
    },
    async updatePromptpayPinVerify(payload: any): Promise<void> {
      await useEvent().setUpdate(this.eventId, payload);

    },
    async getPairCodeFromEvent(pairCode: string) {
      return await useEvent().getEventByPairCode(pairCode);
    },

    async updatePairCodeIntoEvent(payload) {
      await useEvent().setUpdate(this.eventId, payload);
    },

    async removeFirstPageState() {
      await useEvent().removeFirstPage(this.eventId, "pages");
    },

    fetchEventRealtime() {
      const templateStore = useTemplateStore();
      const notificationStore = useNotificationStore();

      const unSub = useEvent().fetchRealtimeEvent(this.eventId, (event) => {
        this.event = event;
        templateStore.setTemplateSelected(event?.templates);
        notificationStore.fetchUserInfoNotify()
      });
      this.unsubScribe = unSub;
    },

    async saveShipping(payload: any) {
      await this.updateEvent({ shipping: payload });
    },
    async saveProduceBook(payload: any) {
      await this.updateEvent({ produceBook: payload });
    },
    unSubscribeEvent() {
      if (!this.unsubScribe) return;
      this.unsubScribe();
    },
    convertUserIdToEventId(userId: string, lengthNumber = 6) {
      let str = userId.substring(Math.max(userId.length - lengthNumber, 0));
      return str.toLocaleUpperCase()
    },
    async updateMetaConvention(_eventId: string, payload: any) {
      const ref = doc(db, "meta", payload.userInfo.uid);
      const docSnap = await getDoc(ref);
      if (!docSnap.exists()) {
        return;
      }

      const userData = docSnap.data();
      userData.eventId = _eventId;
      userData.name = payload.userInfo.displayName;
      userData.phone = payload.userInfo.phoneNumber;
      userData.email = payload.userInfo.email;

      await setDoc(ref, userData);
    }
  },
});
