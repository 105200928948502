<template>
  <div class="relative w-[300px] rounded-lg" >
    <div class="w-full h-[480px] relative shadow-md rounded-lg">
      <img
        class="w-full h-full object-cover object-left"
        src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/6924954b-5617-4976-40f7-0bd3bacf1500/original"
      />
      <div class="absolute top-0 right-0 bottom-0 left-0">
        <img 
          class="w-full h-full object-cover object-right" 
          src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/a0067765-e615-4e3b-0248-434e21f26c00/original"
        />  
      </div>
    </div>

    <div class="text-center" style="color: rgb(89, 61, 40)">
      
      <div  
        class="text-[12px] w-52 tracking-wide font-bold font-['cinzel'] uppercase absolute right-0" 
        style="top: 70px;"
        >
        <p>Save the Date</p>
        <p>For the wedding of</p>
      </div>

      <div
        class="text-center w-52 absolute right-0" 
        style="position: absolute; top: 125px; right: 0px;"
      >
        <div
          class="capitalize text-center tracking-wide text-[2.2em] py-2 space-y-2"
          :style="{
            fontFamily: props?.theme?.fonts?.welcome,
            color: '#7c5340',
            fontStyle: italic,
            lineHeight: 1,
          }"
        >
          <p>{{ props?.brideName }}</p>
          <p>& {{ props?.groomName }}</p>
        </div>
      </div>
    </div>

    <div  
      class="text-center" 
      style="position: absolute; top:230px; right: 67px;"
    >
      <img  class="w-16 h-auto object-contain" src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/50f263e2-7b56-429e-8839-081e3bcc7b00/original"/>
    </div>


    <div
      class="text-center w-52"
      style="position: absolute; top: 270px; right: 0;"
    >
      <div
        class="text-[10px] capitalize text-center tracking-wide"
        style="font-family: cinzel; color: #7c5340;"
      >
        <p >{{dateNumber}}.{{mount}}.{{year}}</p>
      </div>
    </div>

    <div
      class="text-center w-52 text-[10px] tracking-wide uppercase absolute" 
      style="font-family: cinzel; position: absolute; top: 290px; right:0;  color: #7c5340;"
    >
      <p class="">OUR SPECIAL DAY</p>
    </div>

    <FooterButtons :theme="theme" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import FooterButtons from './FooterButtons.vue'
const props = defineProps({
  brideName: { type: String, default: 'Bride'},
  groomName: { type: String, default: 'Groom'},
  eventDate: {type: String, default: '2014-12-25'},
  eventTimeStart: { type: String, default: '00:00'},
  theme: { type: Object, default: {}}
})

const mainColor = computed(() => props?.theme?.colors?.main || '#0855cb')

//-----
const dateNumber = computed( ()=> dayjs(props?.eventDate).format('DD') ||  dayjs().format('DD'))
const dayName = computed( ()=> dayjs(props?.eventDate).format('dddd') ||  dayjs().format('dddd'))
const mount = computed( ()=> dayjs(props?.eventDate).format('MMM') ||  dayjs().format('MMM'))
const year = computed( ()=> dayjs(props?.eventDate).format('YYYY') ||  dayjs().format('YYYY'))
const time = computed( ()=> props?.eventTimeStart ||  '00:00')
//-----
</script>

<style scoped>

</style>