<template>
  <div class="relative w-[300px] rounded-lg bg-[#F6F5F1]"  >
    <div class="w-full h-[480px] relative shadow-md rounded-lg">
      <div class="absolute top-0 right-0 bottom-0 left-0">
        <img 
          class="w-full h-full object-cover object-bottom" 
          src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/32fc7aa1-116d-4da5-5400-cce9e5372500/original"
        />  
      </div>
    </div>

    <div class="text-center" style="color: #46596C;">
      
      <div  
        class="text-[26px] w-52 tracking-wide  absolute right-0" 
        style="top: 70px; font-family: Sweet Belly Script;"
        >
        <p>Save the Date</p>
      </div>

      <div  
        class="text-center w-52 absolute right-0" 
        style="top: 190px;"
      >
        <div 
          class=" text-[8px] capitalize text-center tracking-wide" 
          :style="`font-family: Cinzel; line-height: 1; color: #9DA3AF;`"
        >Are getting married</div>
      </div> 

      <div
        class="text-center w-52 absolute right-0" 
        style="position: absolute; top: 125px; right: 0px;"
      >
        <div
          class="capitalize text-center tracking-wide text-[20px] py-2 space-y-2"
          :style="{
            fontFamily: props?.theme?.fonts?.welcome,
            color: '#46596C',
            fontStyle: italic,
            lineHeight: 1,
          }"
        >
          <p>{{ props?.brideName }}</p>
          <p>& {{ props?.groomName }}</p>
        </div>
      </div>
    </div>


    <div class="text-[#46596C]">
      <div 
        class="w-52 text-center absolute right-0" 
        style="top: 220px;"
      >
        <div class="text-[25px] tracking-wide" style="font-family: cinzel;">
          <p>{{dateNumber}}</p>
        </div>
      </div>

      <div 
        class="w-52 text-center absolute right-0" 
        style="top: 255px;"
      >
        <div class="text-[8px] tracking-wide uppercase" style="font-family: cinzel;">
          <p>{{dayName}}</p>
        </div>
      </div>

      <div 
        class="w-52 absolute right-0"
        style="top: 230px;"
      >
        <div class="flex items-center justify-around px-2">
          <div class="w-1/2  px-6 text-[10px] tracking-wide" style="font-family: cinzel;">
            <p class="border-1.5 border-t border-b text-center border-[#46596C]">{{mount}}</p>
          </div>

          <div class="w-1/2  px-6  text-[10px] tracking-wide" style="font-family: cinzel;">
            <p class="border-1.5 border-t border-b text-center border-[#46596C]">{{year}}</p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="text-center w-52 text-[8px] tracking-wide uppercase absolute" 
      style="font-family: cinzel; position: absolute; top: 280px; right:0;  color: #9DA3AF;"
    >
      <p class="">OUR SPECIAL DAY</p>
    </div>

    <FooterButtons :theme="theme" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import FooterButtons from './FooterButtons.vue'
const props = defineProps({
  brideName: { type: String, default: 'Bride'},
  groomName: { type: String, default: 'Groom'},
  eventDate: {type: String, default: '2014-12-25'},
  eventTimeStart: { type: String, default: '00:00'},
  theme: { type: Object, default: {}}
})

//-----
const dateNumber = computed( ()=> dayjs(props?.eventDate).format('DD') ||  dayjs().format('DD'))
const dayName = computed( ()=> dayjs(props?.eventDate).format('dddd') ||  dayjs().format('dddd'))
const mount = computed( ()=> dayjs(props?.eventDate).format('MMM') ||  dayjs().format('MMM'))
const year = computed( ()=> dayjs(props?.eventDate).format('YYYY') ||  dayjs().format('YYYY'))
const time = computed( ()=> props?.eventTimeStart ||  '00:00')
//-----
</script>

<style scoped>

</style>