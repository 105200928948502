import useWish from "@/composables/useWish";
import { useEventStore } from "@/stores/event"
import wishesDatabase from "@/services/database/wishes"
import { genExampleWishes } from '../utils/genExampleData'
import _ from 'lodash'

export const useWishStore = defineStore("wish", {
  state: () => ({
    wishRenderList: [],
    unsubScribe: null,

    sortedWishes: {},
    sortedFavoriteWishes: {}
  }),
  getters: {
    wishedList: (state) => {
      const list = _.filter(state.wishRenderList, w => {
        if(w?.id && w?.templateId && w?.status === 'approved') {
          return w
        }
      })

      if(list?.length === 0) {
        return []
      }

      const sorted = list.sort((a, b) => {
        const timestampA = a.timestamp?.seconds * 1000000 + a.timestamp?.nanoseconds;
        const timestampB = b.timestamp?.seconds * 1000000 + b.timestamp?.nanoseconds;
        return timestampB - timestampA; 
      })

      return sorted
    }
  },
  actions: {
    async fetchWishesRealtime() {
      const eventStore = useEventStore()
      console.log("statr wish load")
      this.unsubScribe = useWish()._realtimeFetch(eventStore.eventId,(wish) => {   
          this.wishRenderList = wish
      });
    },

    async fetchSortedWishesRealtime() {
      const eventStore = useEventStore()
      console.log("statr sorted wishes")
      const type = "wishes"
      this.unsubScribe = useWish()._realtimeFetchSortedWishes(eventStore.eventId, type, (obj) => {   
        this.sortedWishes = obj
      });
    },

    async fetchSortedFavoriteWishesRealtime() {
      const eventStore = useEventStore()
      console.log("statr sorted favorite wishes")
      const type = "favorite"
      this.unsubScribe = useWish()._realtimeFetchSortedWishes(eventStore.eventId, type, (obj) => { 
        this.sortedFavoriteWishes = obj
      });
    },

    async getWishWithPagination(){
      const eventStore = useEventStore()
      await wishesDatabase()._queryWishPagination(eventStore.eventId,false)
    },

    async removeWish(wishId:string){
      const eventStore = useEventStore()
       await wishesDatabase()._remove(eventStore.eventId, wishId)
    },
    unSubscribeWish() {
      if (!this.unsubScribe) return;
      this.unsubScribe();
    },

    async updateWish(wish: any) {
      const eventStore = useEventStore()
      await wishesDatabase()._set(eventStore?.eventId, wish?.id, wish)
    },

    async updateSortedWishes(wishObj: any) {
      const eventStore = useEventStore()
      const subCollName = "wishes"
      await wishesDatabase()._setSorted(eventStore?.eventId, subCollName, wishObj)
    },

    async updateSortedFavoriteWishes(wishObj: any) {
      const eventStore = useEventStore()
      const subCollName = "favorite"
      await wishesDatabase()._setSorted(eventStore?.eventId, subCollName, wishObj)
    },



    // --- example ---
    async createExampleWishes(eventId: string) {
      if(!eventId) {
        return 
      }
      
      const list = genExampleWishes(eventId)

      const promiseList = []

      for (let index = 0; index < list.length; index++) {
        const wish = list[index];
        const wishId = wish?.docId
        console.log(wishId, wish)
        promiseList.push(wishesDatabase()._set(eventId, wishId, wish))
      }

      await Promise.all(promiseList)
      console.log('create example wishes success')
    }
  },
});
