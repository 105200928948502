<template>
  <div class="relative w-[300px] rounded-lg" >
    <div class="w-full h-[480px] relative shadow-md rounded-lg overflow-hidden">
      <div class="absolute -top-10 right-0 bottom-0 left-0 ">
        <img 
          class="w-full h-full object-contain object-right-top" 
          src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/e56a3026-81be-4872-c0dc-8e5bad852a00/hd"
        />  
      </div>
    </div>

    <div class="text-center" style="color: #5B6D49;">
      
      <div
        class="text-center absolute left-0 right-0" 
        style="top: 145px;"
      >
        <div
          class="capitalize text-center tracking-wide text-[28px] py-2 space-y-2"
          :style="{
            fontFamily: props?.theme?.fonts?.welcome,
            color: '#5B6D49',
            lineHeight: 1,
          }"
        >
          <p>{{ props?.brideName }}</p>
          <p>& {{ props?.groomName }}</p>
        </div>
      </div>
    </div>

    <div  
      class="text-center flex justify-center absolute right-0 left-0" 
      style="top: 227px"
    >
      <img  class="w-24 h-auto object-contain" src="https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/005f80c9-632c-4a09-153b-064eac53d300/original"/>
    </div>


    <div
      class="text-center absolute right-0 left-0"
      style="top: 248px;"
    >
      <div
        class="text-[12px] capitalize text-center tracking-wide"
        style="font-family: cinzel; color: #49503F;"
      >
        <p >{{dateNumber}}.{{mount}}.{{year}}</p>
      </div>
    </div>

    <div
      class="text-center text-[10px] tracking-wide uppercase absolute right-0 left-0" 
      style="font-family: cinzel; top: 280px; color: #49503F;"
    >
      <p class="">SHARING THIS SPECIAL</p>
      <p class="">MOMENT TOGETHER</p>
    </div>

    <FooterButtons :theme="theme" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import FooterButtons from './FooterButtons.vue'
const props = defineProps({
  brideName: { type: String, default: 'Bride'},
  groomName: { type: String, default: 'Groom'},
  eventDate: {type: String, default: '2014-12-25'},
  eventTimeStart: { type: String, default: '00:00'},
  theme: { type: Object, default: {}}
})

//-----
const dateNumber = computed( ()=> dayjs(props?.eventDate).format('DD') ||  dayjs().format('DD'))
const dayName = computed( ()=> dayjs(props?.eventDate).format('dddd') ||  dayjs().format('dddd'))
const mount = computed( ()=> dayjs(props?.eventDate).format('MMM') ||  dayjs().format('MMM'))
const year = computed( ()=> dayjs(props?.eventDate).format('YYYY') ||  dayjs().format('YYYY'))
const time = computed( ()=> props?.eventTimeStart ||  '00:00')
//-----
</script>

<style scoped>

</style>