<template>
  <div class="p-3">
    <div class="bg-white rounded-xl shadow">
      <div class="mx-auto max-w-7xl p-6">
        <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2
            class="text-2xl font-bold leading-10 tracking-tight text-gray-900"
          >
            {{content?.title}}
          </h2>
          <dl class="mt-5 space-y-6 divide-y divide-gray-900/10">
            <div class="pt-6" v-for="q in list" :key="q.title">
              <dt>
                <!-- Expand/collapse question button -->
                <button
                  type="button"
                  class="flex w-full items-start justify-between text-left text-gray-900"
                  aria-controls="faq-0"
                  aria-expanded="false"
                  @click="q.open = !q.open"
                >
                  <span class="text-base font-semibold leading-7"
                    >{{ q.title }}
                  </span>
                  <span class="ml-6 flex h-7 items-center">
                    <svg
                      :class="{ hidden: q.open }"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 6v12m6-6H6"
                      />
                    </svg>

                    <svg
                      :class="{ hidden: !q.open }"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18 12H6"
                      />
                    </svg>
                  </span>
                </button>
              </dt>
              <dd v-if="q.open" class="mt-4 pr-12 space-y-6 pl-6" id="faq-0">
                <p class="text-base leading-7 text-gray-500 font-light">
                  {{ q.description }}
                </p>

                <div class="underline text-blue-700">
                  {{ q.value }}
                </div>

                <img
                  v-if="q.type == 'image'"
                  class="w-full rounded-lg"
                  :src="qrImage"
                  alt=""
                />

                <input
                  type="text"
                  :value="`
                  Link สำหรับเขียนอวยพร 
                  ${q.value}`"
                  :id="q.id"
                  name="hs-trailing-button-add-on"
                  class="hidden px-4 block w-full border-gray-400 shadow-sm rounded-l-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                />

                <button
                  v-if="q.id !== 'qrcode'"
                  @click="q.btn.callback(q)"
                  class="w-full py-3 px-4 inline-flex flex-shrink-0 justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-gray-700"
                >
                  {{ q?.btn?.label || q?.label }}
                </button>

                <div v-if="q?.id === 'pair-event'">
                  <p class="text-gray-500  mt-7">Paired Code ({{ pairedCode?.length }})</p>
                  <div
                    class="my-3 block"
                  >
                    <p v-if="pairedCode?.length == 0">ไม่มีข้อมูล</p>
                    <p
                      v-else
                      v-for="(code, i) in pairedCode"
                      class="text-gray-900 text-left my-3 flex justify-between items-center"
                      :key="code?.qrcodeId"
                    >
                      <span>{{ i + 1 }}. {{ code?.qrcodeId }} </span>
                      <span class="text-xs">{{ dayjs(code.updatedAt).format("DD/MMM/YYYY HH:mm") }}</span>
                    </p>
                  </div>
                </div>
              </dd>
            </div>

            <!-- More questions... -->
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import qrcodesDatabase from "~/services/database/qrcodes";
import dayjs from "dayjs";
import { useEventStore } from "@/stores/event";
import { useAuthStore } from "@/stores/auth";
import QRCode from "qrcode";
import _ from 'lodash';

import { useContentWish } from '~/composables/content/v2/useContentWish'
const { content } = useContentWish('wish-share')

const eventStore = useEventStore();
const authStore = useAuthStore();
const router = useRouter()

const pairedCode = ref([]);

const copy = async (id) => {
  // let copyText = document.getElementById(id);
  // copyText.select();
  // copyText.setSelectionRange(0, 99999);

  // const text = _.trim(copyText.value)
  // navigator.clipboard.writeText(text);

  if(id === 'link-guest') {
    const text = `Link สำหรับเขียนอวยพร \n\n${linkQrCode.value}`
    navigator.clipboard.writeText(text);
    return;
  }

  if(id === 'link-slide') {
    const text = linkSlide.value
    navigator.clipboard.writeText(text);
    return;
  }
};
const eventId = ref(eventStore.event.eventId);
const linkQrCode = ref(
  `https://wish-v2.photowish.com/?eventId=${eventId.value}`
);
const linkSlide = ref(
  `https://present-v2.photowish.com/?eventId=${eventId.value}`
);
const linkShareEvent = ref(`https://app-v2.photowish.com/invite/?eventId=${eventId.value}`)

const qrImage = ref(`https://plankton-app-u9jct.ondigitalocean.app/share-qrcode-image?eventId=${eventId.value}`);


function openBase64ImageInNewTab(base64Data) {
    // Open the base64 image in a new tab
    const newTab = window.open();
    newTab.document.body.innerHTML = `<img src="${base64Data}" alt="QR Code Image">`;
}

const list = ref([])

onMounted(async () => {
  setList();
  await fetchPairedCode();

})

watch(() => content.value, () => {
  setList();
})

const fetchPairedCode = async () => {
  const findPairCode = await qrcodesDatabase().getPairCodeByEventId(
    eventStore.eventId
  );

  pairedCode.value = findPairCode;
};

const setList = () => {
  const shareList = content.value?.list

  list.value = _.map(shareList, data => {
    if(data.id === "qrcode") {
      return {
        ...data,
        images: [],
        value: ref(""),
        btn: {
          callback() {
            const a = document.createElement("a");
            a.href = "https://plankton-app-u9jct.ondigitalocean.app/demo";
            a.download = `photowish_qrcode_event_${eventId.value}.png`;
            a.click();
          },
        }
      }
    }

    if(data.id === 'pair-event') {
      return {
        ...data,
        images: [],
        btn: {
          callback(self) {
            router.push("/pairEvent")
          },
        },   
      }
    }


    return {
      ...data,
      images: [],
      value: data.id === 'link-guest' ? linkQrCode.value : data.id === 'link-hotel' ? linkSlide.value : linkShareEvent.value,
      btn: {
        callback(self) {
          // copy to clipboard
          copy(data.id);
          
          self.btn.label = "Copied";
          setTimeout(() => {
            self.btn.label = data.label
          }, 2000);
        },
      }
    }
  })
}

</script>
